/** @jsx jsx */
import { jsx } from "theme-ui";
import { useDeviceDetect } from "./utils";

type InnerProps = {
  className?: string;
  children: React.ReactNode;
};

// const Inner = ({ className = ``, children }: InnerProps) => (
//   <div sx={{ width: [`full`, `full`, `full`, `full`, `full`, `2/3`], textAlign: `left` }} className={className}>
//     {children}
//   </div>
// )

const Inner = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: isMobile ? [0, "1em", 0, "1em"] : [0, "3em", 0, "3em"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: [`100%`, `100%`, `100%`, `100%`, `100%`, `66%`],
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Inner;
