/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useContext } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import SidebarWidthContext from "./context";

type ContentProps = {
  speed: number;
  offset: number;
  children: React.ReactNode;
  className?: string;
  factor?: number;
  padding?: any;
  justifyContent?: string;
};

const Content = ({
  speed,
  offset,
  children,
  className = ``,
  factor = 1,
  padding = [3, 4, 4, 5],
  justifyContent = "center"
}: ContentProps) => {
  const sidebarWidth = React.useContext(SidebarWidthContext);
  return (
    <ParallaxLayer
      sx={{
        // padding: padding,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: justifyContent,
        zIndex: 50,
      }}
      speed={speed}
      offset={offset}
      factor={factor}
      className={className}
    >
      <div style={{ marginLeft: `${sidebarWidth}%` , display: "flex"}}>{children}</div>
    </ParallaxLayer>
  );
};

// export const ConservationContent = ({
//   speed,
//   offset,
//   children,
//   className = ``,
//   factor = 1,
// }: ContentProps) => (
//   <ParallaxLayer
//     sx={{
//       // padding: [3, 4, 4, 5],
//       display: `flex`,
//       flexDirection: `column`,
//       alignItems: `center`,
//       justifyContent: `center`,
//       zIndex: 50,
//       marginLeft: "7.5%",
//     }}
//     speed={speed}
//     offset={offset}
//     factor={factor}
//     className={className}
//   >
//     {children}
//   </ParallaxLayer>
// );

export const HeroContent = ({
  speed,
  offset,
  children,
  className = ``,
  factor = 1,
}: ContentProps) => {
  const sidebarWidth = useContext(SidebarWidthContext);
  return (
  <ParallaxLayer
    sx={{
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      justifyContent: `top`,
      zIndex: 50,
      marginLeft: `${sidebarWidth}`,
    }}
    speed={speed}
    offset={offset}
    factor={factor}
    className={className}
  >
    {children}
  </ParallaxLayer>
)};

export default Content;
